import React, { ReactElement } from 'react';
import './Callout.scss';

type Props = {
  lang: string;
  title: string;
  linkTitle?: ReactElement;
  children: ReactElement | ReactElement[];
  additionalClass?: string;
};

/**
 * Warning Callout
 *
 * @param props
 * @constructor
 */
const WarningCallout: React.FunctionComponent<Props> = props => {
  return (
    <div className="ontario-alert ontario-alert--warning">
      <div className="ontario-alert__header">
        <div className="ontario-alert__header-icon">
          <svg
            className="ontario-icon"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 24 24"
            preserveAspectRatio="xMidYMid meet"
          >
            <use href="#ontario-icon-alert-warning"></use>
          </svg>
        </div>
        <h2 className="ontario-alert__header-title ontario-h4">
          {props.title} {props.linkTitle ? props.linkTitle : ''}
        </h2>
      </div>
      <div className="ontario-alert__body">
        <p>{props.children}</p>
      </div>
    </div>
  );
};

export default WarningCallout;
