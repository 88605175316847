import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';
import './ProductTabs.scss';
import ProductAPIDetails from './ProductAPIDetails';
import { adjustProductSample } from '../../util/utils';

type Props = {
  class?: string;
  load?: boolean;
  lang: string;
  productId: string;
  productDetails: {
    productName: string;
    name: string;
    sample: any;
    apis: [{ version: string }];
    products: [{ productId: string | number }];
  };
  isPurchased: boolean;
};

/**
 * Product Tabs
 *
 * @param props
 * @constructor
 */
const ProductTabs: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  return (
    <div className={'mdmp__product-tabs ontario-columns ontario-small-12'}>
      <Tabs>
        <TabList>
          <Tab tabIndex={'0'}>
            {t('productDetailsPage.productDetails.tabs.names.dataSamples')}
          </Tab>
          {/*<Tab tabIndex={'0'}>*/}
          {/*  {t('productDetailsPage.productDetails.tabs.names.documentation')}*/}
          {/*</Tab>*/}
          <Tab tabIndex={'0'}>
            {t('productDetailsPage.productDetails.tabs.names.forDevelopers')} (
            {t('productDetailsPage.productDetails.tabs.names.test')})
          </Tab>
          <Tab tabIndex={'0'}>
            {t('productDetailsPage.productDetails.tabs.names.forDevelopers')} (
            {t('productDetailsPage.productDetails.tabs.names.prod')})
          </Tab>
        </TabList>
        <TabPanel>
          <div className={'ontario-row'}>
            <div className="ontario-columns ontario-small-12 ontario-medium-6">
              <p className={'mdmp__product-subtitle'}>
                {t(
                  'productDetailsPage.productDetails.tabs.dataSamples.sampleFields',
                )}
              </p>

              {adjustProductSample(props.productDetails.sample).map((v, k) => {
                return (
                  <div key={k}>
                    <p>{v.version}</p>

                    {v.sample.map((vv, kk) => {
                      return (
                        <p key={kk}>
                          <b>{vv[props.lang]}</b>
                          <br />
                          {vv.value}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="ontario-columns ontario-small-12 ontario-medium-6">
              {/*<img*/}
              {/*  className={'mdmp__tab-product-image'}*/}
              {/*  alt={t('productDetailsPage.productDetails.title')}*/}
              {/*  src={odometerReadingImage}*/}
              {/*/>*/}
              <p className={'mdmp__product-subtitle'}>
                {props.productDetails.productName}
              </p>
              {/*<p>*/}
              {/*  {t('productDetailsPage.productDetails.tabs.dataSamples.text01')}*/}
              {/*  <br />*/}
              {/*  {t('productDetailsPage.productDetails.tabs.dataSamples.text02')}*/}
              {/*  <br />*/}
              {/*  {t('productDetailsPage.productDetails.tabs.dataSamples.text03')}*/}
              {/*</p>*/}
            </div>
          </div>
        </TabPanel>
        {/*<TabPanel>*/}
        {/*  <div className={'ontario-row'}>*/}
        {/*    <div*/}
        {/*      className={'ontario-columns ontario-small-12 ontario-medium-4'}*/}
        {/*    >*/}
        {/*      <Link to={'#a'}>Overview of the API</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#b'}>Batch Updates</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#c'}>Document ID</Link>*/}
        {/*      <br />*/}
        {/*      <hr />*/}
        {/*      <Link to={'#a1'}>Quick Start</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#b2'}>How to</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#c3'}>Document Libraries</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#d4'}>Developer Guides</Link>*/}
        {/*      <br />*/}
        {/*    </div>*/}

        {/*    <div*/}
        {/*      className={'ontario-columns ontario-small-12 ontario-medium-4'}*/}
        {/*    >*/}
        {/*      <Link to={'#a'}>Overview of the API</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#b'}>Batch Updates</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#c'}>Document ID</Link>*/}
        {/*      <br />*/}
        {/*      <hr />*/}
        {/*      <Link to={'#a1'}>Quick Start</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#b2'}>How to</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#c3'}>Document Libraries</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#d4'}>Developer Guides</Link>*/}
        {/*      <br />*/}
        {/*    </div>*/}

        {/*    <div*/}
        {/*      className={'ontario-columns ontario-small-12 ontario-medium-4'}*/}
        {/*    >*/}
        {/*      <Link to={'#a'}>Overview of the API</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#b'}>Batch Updates</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#c'}>Document ID</Link>*/}
        {/*      <br />*/}
        {/*      <hr />*/}
        {/*      <Link to={'#a1'}>Quick Start</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#b2'}>How to</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#c3'}>Document Libraries</Link>*/}
        {/*      <br />*/}
        {/*      <Link to={'#d4'}>Developer Guides</Link>*/}
        {/*      <br />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</TabPanel>*/}

        <TabPanel>
          <ProductAPIDetails
            lang={props.lang}
            productId={props.productId + '-uat'}
            productDetails={props.productDetails}
            isPurchased={props.isPurchased}
          />
        </TabPanel>

        <TabPanel>
          <ProductAPIDetails
            lang={props.lang}
            productId={props.productId}
            productDetails={props.productDetails}
            isPurchased={props.isPurchased}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ProductTabs;
