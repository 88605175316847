import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import * as React from 'react';
import { getPageLink, LANG_CONFIG, SITE_CONFIG } from '../constants';
import NotFoundPage from '../pages/404';
import ProductDetailsPage from './ProductDetailsPage';
import './ProductDetailsPage.scss';
import { PageContentType } from '../context/PageContentType';

interface Props extends PageContentType {
  productId?: string;
}

/**
 * Product Page
 *
 * @param props
 * @constructor
 */
const ProductPage: React.FunctionComponent<Props> = props => {
  /**
   * This next line is VERY important, as it solves a server-side rendering issue for client-only pages
   * Without this line, the page will not render properly if accessed directly (i.e. refreshing the page)
   * See the README for more information
   */
  if (typeof window === 'undefined') return null;
  return (
    <Router
      basepath={
        LANG_CONFIG[props.pageContext.lang.toUpperCase() + '_Prefix'] +
        SITE_CONFIG.PRODUCT[props.pageContext.lang.toUpperCase()]
      }
    >
      <ProductDetailsPage
        path={
          SITE_CONFIG.PRODUCT.PAGES.PRODUCT_DETAILS[
            props.pageContext.lang.toUpperCase()
          ] + '/:productId'
        }
        pageContext={{
          currentLangUrl: getPageLink(
            props.pageContext.lang,
            'PRODUCT',
            'PRODUCT_DETAILS',
          ),
          otherLangUrl: getPageLink(
            props.pageContext.otherLang,
            'PRODUCT',
            'PRODUCT_DETAILS',
          ),
          lang: props.pageContext.lang,
          otherLang: props.pageContext.otherLang,
        }}
      />
      <NotFoundPage default />
    </Router>
  );
};

export default ProductPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
