import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-tabs/style/react-tabs.css';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import {
  ERROR_ENTITY,
  GQL_GET_SUBSCRIPTION_KEYS,
  GQL_REGENERATE_PRIMARY_KEY,
  GQL_REGENERATE_SECONDARY_KEY,
} from '../../constants';
import {
  SELECT_PRODUCT_API_VERSION,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_SUBSCRIPTION_KEYS,
} from '../../context/actions/ActionTypes';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { errorHandler, showUnexpectedError } from '../../errorHandler';
import odometerReadingImage from '../../images/odometer-reading-image.png';
import './ProductTabs.scss';

type Props = {
  lang: string;
  productDetails: {
    productName: string;
    name: string;
    sample: any;
    apis: [{ version: string }];
    products: [{ productId: string | number }];
  };
  productId: string;
  isPurchased: boolean;
};

/**
 * Product API Details
 *
 * @param props
 * @constructor
 */
const ProductAPIDetails: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [primaryKeyShow, setPrimaryKeyShow] = useState(false);
  const [secondaryKeyShow, setSecondaryKeyShow] = useState(false);

  const defaultValue = '*************************';

  /**
   * Get Product data
   */
  const getProductData = (): any => {
    return props.productDetails.products.filter(
      v => v.productId === props.productId,
    )[0];
  };

  const productData = getProductData();

  /**
   * Get API Specification for Swagger
   */
  const getSpecification = () => {
    const api = productData.apis.filter(
      v => v.version === state.selectedProductAPI[props.productId],
    )[0];

    return JSON.parse(api.specification);
  };

  const apiSpec = getSpecification();

  const [
    getSubscriptionKeys,
    {
      loading: getSubscriptionKeysLoading,
      data: getSubscriptionKeysData,
      error: getSubscriptionKeysError,
    },
  ] = useLazyQuery(
    gql`
      ${GQL_GET_SUBSCRIPTION_KEYS}
    `,
    {
      variables: {
        productId: props.productId,
      },
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (getSubscriptionKeysLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (getSubscriptionKeysError) {
      errorHandler(
        dispatch,
        t,
        getSubscriptionKeysError,
        ERROR_ENTITY.GEY_KEYS,
        props.lang,
      );

      return;
    }

    if (getSubscriptionKeysData) {
      if (getSubscriptionKeysData?.subscriptionKeys) {
        dispatch({
          type: SET_SUBSCRIPTION_KEYS,
          productId: props.productId,
          primaryKey: getSubscriptionKeysData.subscriptionKeys.primaryKey,
          secondaryKey: getSubscriptionKeysData.subscriptionKeys.secondaryKey,
        });

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [
    getSubscriptionKeysLoading,
    getSubscriptionKeysData,
    getSubscriptionKeysError,
  ]);

  // Regenerate primary key
  const [
    regeneratePrimaryKey,
    {
      loading: regeneratePrimaryKeyLoading,
      data: regeneratePrimaryKeyData,
      error: regeneratePrimaryKeyError,
    },
  ] = useMutation(
    gql`
      ${GQL_REGENERATE_PRIMARY_KEY}
    `,
    {
      variables: {
        productId: props.productId,
      },
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (regeneratePrimaryKeyLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (regeneratePrimaryKeyError) {
      errorHandler(
        dispatch,
        t,
        regeneratePrimaryKeyError,
        ERROR_ENTITY.PRIMARY_KEY,
        props.lang,
      );

      return;
    }

    if (regeneratePrimaryKeyData) {
      if (regeneratePrimaryKeyData?.regenerateSubscriptionPrimaryKey) {
        dispatch({
          type: SET_LOADING_ON,
        });

        getSubscriptionKeys();
        setPrimaryKeyShow(false);
        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [
    regeneratePrimaryKeyLoading,
    regeneratePrimaryKeyData,
    regeneratePrimaryKeyError,
  ]);

  // Regenerate secondary key
  const [
    regenerateSecondaryKey,
    {
      loading: regenerateSecondaryKeyLoading,
      data: regenerateSecondaryKeyData,
      error: regenerateSecondaryKeyError,
    },
  ] = useMutation(
    gql`
      ${GQL_REGENERATE_SECONDARY_KEY}
    `,
    {
      variables: {
        productId: props.productId,
      },
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (regenerateSecondaryKeyLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (regenerateSecondaryKeyError) {
      errorHandler(
        dispatch,
        t,
        regenerateSecondaryKeyError,
        ERROR_ENTITY.SECONDARY_KEY,
        props.lang,
      );

      return;
    }

    if (regenerateSecondaryKeyData) {
      if (regenerateSecondaryKeyData?.regenerateSubscriptionSecondaryKey) {
        dispatch({
          type: SET_LOADING_ON,
        });

        getSubscriptionKeys();
        setSecondaryKeyShow(false);

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [
    regenerateSecondaryKeyLoading,
    regenerateSecondaryKeyData,
    regenerateSecondaryKeyError,
  ]);

  /**
   * Handle Show Key
   *
   * @param keyType
   */
  const handleShowKey = keyType => {
    getSubscriptionKeys();

    if (keyType === 'primary') {
      setPrimaryKeyShow(!primaryKeyShow);
    }

    if (keyType === 'secondary') {
      setSecondaryKeyShow(!secondaryKeyShow);
    }
  };

  /**
   * Handle regenerate key
   *
   * @param keyType
   */
  const handleRegenerateKey = keyType => {
    if (keyType === 'primary') {
      regeneratePrimaryKey();
    }

    if (keyType === 'secondary') {
      regenerateSecondaryKey();
    }
  };

  return (
    <>
      <div className={'ontario-row'}>
        <div
          className={'mdmp-dev-product-block ontario-columns ontario-small-12'}
        >
          <img src={odometerReadingImage} alt={props.productDetails.name} />
          <div className={'mdmp-dev-product-title'}>
            {props.productDetails.name}
          </div>
        </div>
      </div>

      <div className={'ontario-row'}>
        <div
          className={'mdmp-dev-api-keys-block ontario-columns ontario-small-12'}
        >
          <div className="flex align-center ontario-margin-bottom-16-!">
            <label
              className="ontario-label ontario-margin-bottom-0-!"
              htmlFor="mdmp__primary-key"
            >
              {t(
                'productDetailsPage.productDetails.tabs.forDevelopers.primaryKey',
              )}
            </label>

            {props.isPurchased ? (
              <button
                className="simple flex-no-resize"
                onClick={() => handleShowKey('primary')}
              >
                <svg
                  className="mdmp__key-field ontario-icon"
                  aria-label={t(
                    'productDetailsPage.productDetails.tabs.forDevelopers.' +
                      (primaryKeyShow ? 'hide' : 'show') +
                      'Key',
                  )}
                  focusable="false"
                  viewBox="0 0 28 23"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <use
                    href={
                      !primaryKeyShow
                        ? '#ontario-icon-password-show'
                        : '#ontario-icon-password-hide'
                    }
                  ></use>
                </svg>
              </button>
            ) : (
              <div></div>
            )}

            <input
              className="ontario-input flex-resize ontario-margin-bottom-0-!"
              type={
                props.isPurchased
                  ? primaryKeyShow
                    ? 'text'
                    : 'password'
                  : 'text'
              }
              id="mdmp__primary-key"
              value={
                props.isPurchased
                  ? primaryKeyShow
                    ? state.apiKeys[props.productId].primaryKey
                    : defaultValue
                  : t(
                      'productDetailsPage.productDetails.tabs.forDevelopers.availableUponPurchase',
                    )
              }
              readOnly={true}
            />

            {props.isPurchased ? (
              <button
                className="simple flex-no-resize"
                onClick={() => handleRegenerateKey('primary')}
              >
                <svg
                  className="mdmp__regenerate-key-field ontario-icon"
                  aria-label={t(
                    'productDetailsPage.productDetails.tabs.forDevelopers.regenerateKey',
                  )}
                  focusable="false"
                  viewBox="0 0 28 23"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <use href="#ontario-icon-replay"></use>
                </svg>
              </button>
            ) : (
              <div></div>
            )}
          </div>

          <div className="flex align-center">
            <label
              className="ontario-label ontario-margin-bottom-0-!"
              htmlFor="mdmp__secondary-key"
            >
              {t(
                'productDetailsPage.productDetails.tabs.forDevelopers.secondaryKey',
              )}
            </label>

            {props.isPurchased ? (
              <button
                className="simple flex-no-resize"
                onClick={() => handleShowKey('secondary')}
              >
                <svg
                  className="mdmp__key-field ontario-icon"
                  aria-label={t(
                    'productDetailsPage.productDetails.tabs.forDevelopers.' +
                      (secondaryKeyShow ? 'hide' : 'show') +
                      'Key',
                  )}
                  focusable="false"
                  viewBox="0 0 28 23"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <use
                    href={
                      !secondaryKeyShow
                        ? '#ontario-icon-password-show'
                        : '#ontario-icon-password-hide'
                    }
                  ></use>
                </svg>
              </button>
            ) : (
              <div></div>
            )}

            <input
              className="ontario-input flex-resize ontario-margin-bottom-0-!"
              type={
                props.isPurchased
                  ? secondaryKeyShow
                    ? 'text'
                    : 'password'
                  : 'text'
              }
              id="mdmp__secondary-key"
              value={
                props.isPurchased
                  ? secondaryKeyShow
                    ? state.apiKeys[props.productId].secondaryKey
                    : defaultValue
                  : t(
                      'productDetailsPage.productDetails.tabs.forDevelopers.availableUponPurchase',
                    )
              }
              readOnly={true}
            />

            {props.isPurchased ? (
              <button
                className="simple flex-no-resize"
                onClick={() => handleRegenerateKey('secondary')}
              >
                <svg
                  className="mdmp__regenerate-key-field ontario-icon"
                  aria-label={t(
                    'productDetailsPage.productDetails.tabs.forDevelopers.regenerateKey',
                  )}
                  focusable="false"
                  viewBox="0 0 28 23"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <use href="#ontario-icon-replay"></use>
                </svg>
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>

      <div className={'ontario-row'}>
        <div
          className={'mdmp-api-versions-block ontario-columns ontario-small-12'}
        >
          <div className="ontario-form-group">
            <fieldset className="ontario-fieldset">
              <div className="ontario-radios ontario-margin-bottom-0-!">
                {productData.apis.map((v, k) => {
                  return (
                    <div key={k} className="ontario-radios__item">
                      <input
                        className="ontario-radios__input"
                        id={`radio-button-option-${v.version}`}
                        name="radio-buttons"
                        type="radio"
                        checked={
                          state.selectedProductAPI[props.productId] ===
                          v.version
                        }
                        onChange={() => {
                          dispatch({
                            type: SELECT_PRODUCT_API_VERSION,
                            productId: props.productId,
                            productAPIVersion: v.version,
                          });
                        }}
                      />
                      <label
                        className="ontario-label ontario-radios__label"
                        htmlFor={`radio-button-option-${v.version}`}
                      >
                        {v.version}
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <SwaggerUI spec={apiSpec} />
    </>
  );
};

export default ProductAPIDetails;
