import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Callout from '../components/common/callout/Callout';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import ProductTabs from '../components/product/ProductTabs';
import {
  ERROR_ENTITY,
  getPageLink,
  GQL_ADD_TO_CART,
  GQL_GET_PRODUCT_DATA,
} from '../constants';
import {
  ADD_TO_CART,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PRODUCT_DATA,
} from '../context/actions/ActionTypes';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { errorHandler, showUnexpectedError } from '../errorHandler';
import odometerReadingImage from '../images/odometer-reading-image.png';
import {
  doLogin,
  focusElement,
  isUserProfileComplete,
  timeConverter,
} from '../util/utils';
import './ProductDetailsPage.scss';
import WarningCallout from '../components/common/callout/WarningCallout';
import { PageContentType } from '../context/PageContentType';

interface Props extends PageContentType {
  productId?: string;
}

/**
 * Product Details Page
 * @param props
 * @constructor
 */
const ProductDetailsPage: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const { authState, oktaAuth } = useOktaAuth
    ? useOktaAuth()
    : { authState: null, oktaAuth: null };

  const loggedIn = authState?.isAuthenticated ? true : false;

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const product = state.products[props.productId];
  const productAddedMessage = useRef();
  const [justAddedToCart, setJustAddedToCart] = useState(false);

  const isProductInCart = () => {
    if (state.userProfile.cart?.length) {
      const isInCart = state.userProfile.cart.filter(v => {
        return v.productId === props.productId;
      });

      if (isInCart.length) {
        return true;
      }
    }

    return false;
  };

  /**
   * Get Purchase Data
   */
  const getPurchaseData = () => {
    let isPurchased = false;
    let purchasedAt = '';

    if (state.userProfile.subscriptions?.length) {
      const data = state.userProfile.subscriptions.filter(
        v => v.productId === props.productId,
      );

      if (0 in data && 'productId' in data[0]) {
        isPurchased = true;
        purchasedAt = timeConverter(data[0].createdOn);
      }
    }

    return { isPurchased, purchasedAt };
  };

  const productInCart = isProductInCart();

  // Focus on the added to cart message for accessibility reasons
  useEffect(() => {
    if (productAddedMessage.current && justAddedToCart) {
      focusElement(productAddedMessage, false);
    }
  }, [productAddedMessage.current, justAddedToCart]);

  const { isPurchased, purchasedAt } = getPurchaseData();
  const isAuthorized = state.userProfile.isAuthorized ? true : false;

  const isUserProfileDone = isUserProfileComplete(state.userProfile);

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.products'),
      path: getPageLink(props.pageContext.lang, 'ALL_PRODUCTS_PAGE'),
    },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.productDetails'),
      path:
        getPageLink(props.pageContext.lang, 'PRODUCT', 'PRODUCT_DETAILS') +
        '/' +
        props.productId,
    },
  ];

  const [
    getProductData,
    { loading: productDataLoading, data: productData, error: productDataError },
  ] = useLazyQuery(
    gql`
      ${GQL_GET_PRODUCT_DATA}
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    dispatch({
      type: SET_LOADING_ON,
    });

    getProductData({
      variables: {
        productIds: [props.productId],
      },
    });
  }, []);

  useEffect(() => {
    if (productDataLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (productDataError) {
      errorHandler(
        dispatch,
        t,
        productDataError,
        ERROR_ENTITY.PRODUCT_DETAILS,
        props.pageContext.lang,
      );

      return;
    }

    if (productData) {
      if (productData?.dataProductsDetails) {
        dispatch({
          type: SET_PRODUCT_DATA,
          productId: productData.dataProductsDetails[0].productId,
          productData: productData.dataProductsDetails[0],
        });

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [productDataLoading, productData, productDataError]);

  const [
    addToCart,
    { loading: addToCartLoading, data: addToCartData, error: addToCartError },
  ] = useMutation(
    gql`
      ${GQL_ADD_TO_CART}
    `,
    {
      variables: {
        productId: props.productId,
      },
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (addToCartLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (addToCartError) {
      errorHandler(
        dispatch,
        t,
        addToCartError,
        ERROR_ENTITY.ADD_TO_CART,
        props.pageContext.lang,
      );

      return;
    }

    if (addToCartData) {
      if (addToCartData?.addToCart) {
        dispatch({
          type: ADD_TO_CART,
          productsToAdd: addToCartData.addToCart,
        });

        setJustAddedToCart(true);

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [addToCartLoading, addToCartData, addToCartError]);

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl + '/' + props.productId}
      otherLangUrl={props.pageContext.otherLangUrl + '/' + props.productId}
      pageId={
        getPageLink(props.pageContext.lang, 'PRODUCT', 'PRODUCT_DETAILS') +
        '/' +
        props.productId
      }
      pageTitle={product?.name}
    >
      <div className="ontario-row">
        <div className="mdmp__product-details ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          {product && (
            <>
              <div className={'mdmp__product-main-info'}>
                <h1>{product.name}</h1>

                {/*{product.tags.map((v, k) => {*/}
                {/*  return (*/}
                {/*    <div key={k} className={'mdmp__product-tag'}>*/}
                {/*      {v}*/}
                {/*    </div>*/}
                {/*  );*/}
                {/*})}*/}
              </div>

              <div className="ontario-row">
                <div className="mdmp__product-details ontario-columns ontario-small-12 ontario-medium-4">
                  <img
                    className={'mdmp__product-image'}
                    alt=""
                    src={odometerReadingImage}
                  />
                </div>
                <div className="mdmp__product-details ontario-columns ontario-small-12 ontario-medium-8">
                  <p>{product.description}</p>

                  {loggedIn && !isAuthorized ? (
                    <WarningCallout
                      lang={props.pageContext.lang}
                      title={t('productDetailsPage.callOut.title')}
                      additionalClass={'mdmp__grey'}
                    >
                      {t('productDetailsPage.callOut.notPurchasedDesc01')}
                      <br />
                      <Trans
                        i18nKey={
                          'productDetailsPage.callOut.notPurchasedDesc02'
                        }
                      >
                        <a href={t('common.aris.url')}>Part1</a>
                      </Trans>
                      <br />
                      <Trans
                        i18nKey={
                          'productDetailsPage.callOut.notPurchasedDesc03'
                        }
                      >
                        <a href={'#'} onClick={() => doLogin(oktaAuth)}>
                          Part1
                        </a>
                      </Trans>
                    </WarningCallout>
                  ) : loggedIn && isAuthorized && !isUserProfileDone ? (
                    <Callout
                      additionalClass={'mdmp__blue'}
                      lang={props.pageContext.lang}
                      title={t('callout.completeProfileCallout.title')}
                    >
                      <p>
                        <Trans i18nKey={'callout.completeProfileCallout.desc'}>
                          Part1
                          <Link
                            to={getPageLink(
                              props.pageContext.lang,
                              'USER',
                              'USER_PROFILE',
                            )}
                          >
                            Part2
                          </Link>
                          Part3
                        </Trans>
                      </p>

                      <div className={'mdmp__callout-cta'}>
                        <button
                          className="ontario-button ontario-button--secondary"
                          onClick={() =>
                            navigate(
                              getPageLink(
                                props.pageContext.lang,
                                'USER',
                                'USER_PROFILE',
                              ),
                            )
                          }
                        >
                          {t('callout.completeProfileCallout.buttonText')}
                        </button>
                      </div>
                    </Callout>
                  ) : loggedIn && isAuthorized && !isPurchased ? (
                    <div className={'mdmp__product-add-to-cart'}>
                      <div>
                        <img
                          className={'mdmp__product-image-cart'}
                          alt=""
                          src={odometerReadingImage}
                        />
                      </div>
                      <div>
                        <p className="ontario-margin-bottom-0-!">
                          <strong>{product.name}</strong>
                        </p>
                        {/*<span className={'mdmp__price-text'}>*/}
                        {/*  {t('productDetailsPage.subscriptionPricing')}*/}
                        {/*</span>*/}
                        {state.userProfile?.cart ? (
                          !productInCart ? (
                            <button
                              className={
                                'mdmp__add-to-cart-button ontario-button ontario-button--primary'
                              }
                              onClick={() => addToCart()}
                            >
                              {t('buttons.addToCart')}
                            </button>
                          ) : (
                            <p
                              className={'mdmp__added-to-cart'}
                              ref={productAddedMessage}
                            >
                              {t('productDetailsPage.productAddedToCart')}
                            </p>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {loggedIn && isAuthorized && isPurchased ? (
                    <div className="ontario-alert ontario-alert--informational">
                      <div className="ontario-alert__header">
                        <div className="ontario-alert__header-icon">
                          <svg
                            className="ontario-icon"
                            aria-hidden="true"
                            focusable="false"
                            viewBox="0 0 24 24"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <use href="#ontario-icon-alert-information"></use>
                          </svg>
                        </div>
                        <h2 className="ontario-alert__header-title ontario-h4">
                          {t('productDetailsPage.purchased')} {purchasedAt}
                        </h2>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="ontario-row">
                <div className="mdmp__product-details ontario-columns ontario-small-12 ontario-medium-12">
                  {!loggedIn ? (
                    <WarningCallout
                      lang={props.pageContext.lang}
                      title={t('productDetailsPage.callOut.title')}
                      additionalClass={'mdmp__grey'}
                    >
                      <Trans i18nKey={'productDetailsPage.callOut.anonDesc'}>
                        Part1
                        <a href={'#'} onClick={() => doLogin(oktaAuth)}>
                          Part2
                        </a>
                        Part3
                        <a href={t('common.aris.url')}>Part4</a>
                        Part5
                      </Trans>
                    </WarningCallout>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="mdmp__more-product-info ontario-row">
                <h2>{t('productDetailsPage.moreProductInformation')}</h2>

                {product && (
                  <ProductTabs
                    lang={props.pageContext.lang}
                    productId={props.productId}
                    productDetails={product}
                    isPurchased={isPurchased}
                  ></ProductTabs>
                )}
              </div>
            </>
          )}
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default ProductDetailsPage;
